import Preloader from "./Preloader";
import Nav from "../Components/Nav";
import { Link } from "react-router-dom";

const Load = () => {
  return (
    <div className="">
      <div className="mask-container bg-black">
        <Preloader />
      </div>
      <div className="">
        <div className="relative h-[30vh] large:h-[45vh] w-full flex justify-start mt-[9vh] large:mt-[8vh] object-cover">
          <img
            src="/Assets/newbg.jpg"
            className="small:w-full small:h-full large:w-[50%] justify-start object-cover z-0 border-b-2"
          ></img>
          <img
            src="/Assets/photo.jpg"
            className="small:hidden large:inline large:w-[50%] object-cover justify-end z-0 border-b-2"
          ></img>
          <h1 className="absolute z-20 med:text-black text-[30px] large:text-[50px] mt-[-28vh] large:mt-[-40vh] ml-[5vw] Vibes leading-[2rem]">
            Velkommen til <br />
            {/* <span className="Bebas">Præstø Smørrebrød</span> */}
          </h1>
          {/* <p className="absolute z-20 text-white text-sm mt-[-17vh] ml-[5vw]">
            Lorem Ipsum is simply dummy text <br /> of the printing and
            typesetting industry.
          </p> */}
        </div>

        <div className="relative w-full small:h-[110vh] med:h-[80vh] large:h-[60vh] list-none text-center border-b bg-white">
          {/* <h2 className="text-[#FB8B24] Bebas z-20 text-3xl text-center mt-[3vh] animate-bounce">
            Åbningstider
          </h2> */}
          <div className="absolute mt-[-5vh] flex justify-center items-center w-[100%] ">
            <div className="w-[80%] bg-white mb-5">
              <h2 className="Bebas small:text-[20px] large:text-[40px] mt-3">Lille smørrebrødsforretning med stort hjerte!</h2>
              <br/>
              <p className="text-start text-sm ml-[4%] font-serif w-[90%] mb-3">Kom forbi Præstø Smørrebrød og nyd din frokost eller kaffe ved vores udendørs bordebænkesæt ved vandet og Antonitterstien,
                som giver anledning til en smuk gåtur langs fjorden, havnen og stranden
                <br/><br/>
                Uanset om Du/I er på cykel, knallert, bil eller autocamper m.m, så er der mulighed for parkering hos os!
                <br/><br/>
                Vores forretning er ikke voldsom stor - men det er vores kundeservice til gengæld.
                <br/><br/>
                Hos os kan man få "næsten alt", hvad hjertet begærer.
                <br/><br/>
                Vi har bl.a altid frisklavet smørrebrød i 3 forskellige størrelser (ønskes andet end der findes i vores montre), laver vi det selvfølgelig til dig/jer.
                <br/><br/>
                Store LUNE sandwich (3 slags brød og 19 forskellige slags indhold).
                <br/><br/>
                Hjemmelavede lune frikadeller.
                <br/><br/>
                Diverse menu'er, bl.a. Fish'n chips, kebabmix, pølsemix mm.
                <br/><br/>
                Forudbestillinger på telefon 42604720 er altid velkomne, for så sørger vi for , at det er klart ved ankomst/afhentning.
                <br/>
                <br/>
                <span className="uppercase">Præstø smørrebrød er altid et besøg værd</span>
                <br/>
                <br/>
                <span className="font-bold">Åbningstider:</span>
                <br/>
                Mandag - Fredag 09:00 - 14:30
                <br/>
                Lørdag - KUN ved forudbestilling
                <br/>
                Søndag - Lukket
              </p>
            </div>
          </div>
        </div>

        {/* <div className="relative h-[20vh] w-full ">
          <h2 className="text-[#FB8B24] Bebas text-3xl text-center mt-[3vh]">Vores Menukort</h2>
          <div className="text-white flex flex-col items-center gap-3 mt-4 h-full">
            <Link to="/menukort/smørrebrød"><div className="border-2 h-[5vh] w-[100%] mr-5 pt-[5%] pb-[5%] text-center">Smørrebrød</div></Link>
            <Link to="/menukort/smørrebrød"><div className="border-2 h-[5vh] w-[100%] mr-5 pt-[5%] pb-[5%] text-center">Sandwich</div></Link>
            <Link to="/menukort/smørrebrød"><div className="border-2 h-[5vh] w-[100%] mr-5 pt-[5%] pb-[5%] text-center">Menuer</div></Link>
          </div>
        </div> */}

       


        <div className="relative h-[30vh] w-full list-none text-center small:mt-[13vh] large:mt-[0vh]">
          <h3 className="text-[#FB8B24] Bebas text-3xl text-center mt-[5vh]">
            Bestilling
          </h3>
          <li className="text-white mt-[3vh] relative">
            <span className="text-white absolute small:ml-[-6vw] med:ml-[-4vw] large:ml-[-2vw] mt-[0.8vh]">
              <svg
                width="15"
                height="15"
                viewBox="0 0 59 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.2656 0.00259828C47.0271 0.00259828 59 11.9754 59 26.737C59 28.2696 57.767 29.5026 56.2344 29.5026C54.7018 29.5026 53.4688 28.2696 53.4688 26.737C53.4688 15.0292 43.9734 5.53385 32.2656 5.53385C30.733 5.53385 29.5 4.30084 29.5 2.76822C29.5 1.23561 30.733 0.00259828 32.2656 0.00259828ZM33.1875 22.1276C34.1655 22.1276 35.1034 22.5161 35.795 23.2076C36.4865 23.8992 36.875 24.8371 36.875 25.8151C36.875 26.7931 36.4865 27.731 35.795 28.4226C35.1034 29.1141 34.1655 29.5026 33.1875 29.5026C32.2095 29.5026 31.2716 29.1141 30.58 28.4226C29.8885 27.731 29.5 26.7931 29.5 25.8151C29.5 24.8371 29.8885 23.8992 30.58 23.2076C31.2716 22.5161 32.2095 22.1276 33.1875 22.1276ZM29.5 13.8307C29.5 12.2981 30.733 11.0651 32.2656 11.0651C40.9197 11.0651 47.9375 18.0829 47.9375 26.737C47.9375 28.2696 46.7045 29.5026 45.1719 29.5026C43.6393 29.5026 42.4062 28.2696 42.4062 26.737C42.4062 21.1366 37.866 16.5963 32.2656 16.5963C30.733 16.5963 29.5 15.3633 29.5 13.8307ZM13.54 0.163926C15.7756 -0.446816 18.1148 0.694005 19.0021 2.83736L23.6115 13.8999C24.3951 15.7782 23.8535 17.9561 22.2748 19.2352L16.5938 23.8907C20.4311 32.0032 26.9994 38.5715 35.1119 42.4088L39.7559 36.7278C41.0465 35.1491 43.2129 34.6075 45.0912 35.3911L56.1537 40.0005C58.2971 40.8878 59.4379 43.227 58.8271 45.4626L56.0615 55.6032C55.5199 57.6083 53.6992 59.0026 51.625 59.0026C23.116 59.0026 0 35.8866 0 7.3776C0 5.30338 1.39434 3.48268 3.39941 2.92955L13.54 0.163926Z"
                  fill="url(#paint0_linear_827_6)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_827_6"
                    x1="29.5"
                    y1="0"
                    x2="29.5"
                    y2="59.0026"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            +45 42 60 47 20
          </li>
          <p className="text-white text-xl mt-[3vh] ml-[0vw] uppercase Bebas">
            Jernbanevej 4 4720 Præstø
          </p>
          <p className="text-white text-md mt-[0vh] ml-[0vw] uppercase Bebas">
            praestoesmorrebrod@gmail.com
          </p>
          <a href="https://www.facebook.com/Smorrbroed"><p className="text-[#FB8B24] text-md mt-[0vh] ml-[0vw] uppercase Bebas animate-pulse">
          Vores Facebook!
          </p></a>
          
          
        </div>

        
        <div className="relative h-[13vh] w-full list-none text-center ">
          <div className="text-white text-sm mt-[3vh] ml-[-0vw] relative">
            Alle rettigheder forbeholdes <br />
            <div className="small:ml-[-15vw] med:ml-[-5vw] xtreme:ml-[-3vw]">
              Præstø Smørrebrød{" "}
              <span className="absolute small:ml-[6vw] med:ml-[3vw] large:ml-[30px] xtreme:ml-[40px]">2023</span>
              <span className="absolute mt-[0.6vh] small:ml-[2vw] med:ml-[1vw] xtreme:ml-[0.7vw]">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27 5.0625C32.8182 5.0625 38.3981 7.37377 42.5122 11.4878C46.6262 15.6019 48.9375 21.1818 48.9375 27C48.9375 32.8182 46.6262 38.3981 42.5122 42.5122C38.3981 46.6262 32.8182 48.9375 27 48.9375C21.1818 48.9375 15.6019 46.6262 11.4878 42.5122C7.37377 38.3981 5.0625 32.8182 5.0625 27C5.0625 21.1818 7.37377 15.6019 11.4878 11.4878C15.6019 7.37377 21.1818 5.0625 27 5.0625ZM27 54C34.1608 54 41.0284 51.1554 46.0919 46.0919C51.1554 41.0284 54 34.1608 54 27C54 19.8392 51.1554 12.9716 46.0919 7.90812C41.0284 2.84464 34.1608 0 27 0C19.8392 0 12.9716 2.84464 7.90812 7.90812C2.84464 12.9716 0 19.8392 0 27C0 34.1608 2.84464 41.0284 7.90812 46.0919C12.9716 51.1554 19.8392 54 27 54ZM21.0305 32.9695C17.7398 29.6789 17.7398 24.3316 21.0305 21.041C24.3211 17.7504 29.6684 17.7504 32.959 21.041C33.9504 22.0324 35.5535 22.0324 36.5344 21.041C37.5152 20.0496 37.5258 18.4465 36.5344 17.4656C31.2609 12.1922 22.718 12.1922 17.4445 17.4656C12.1711 22.7391 12.1711 31.282 17.4445 36.5555C22.718 41.8289 31.2609 41.8289 36.5344 36.5555C37.5258 35.5641 37.5258 33.9609 36.5344 32.9801C35.543 31.9992 33.9398 31.9887 32.959 32.9801C29.6684 36.2707 24.3211 36.2707 21.0305 32.9801V32.9695Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="text-yellow-500 text-sm mt-[0.5vh] ml-[0vw] animate-pulse"><a href="https://www.findsmiley.dk/Sider/KontrolRapport.aspx?Virk5332231">Kontrolrapport</a></div>
          <p className="text-white text-sm mt-[0.5vh] ml-[0vw]">CVR: 34846162</p>
          <p className="text-white text-[10px] mt-[1vh] ml-[0vw] opacity-30">
            1.20.10
          </p>
        </div>
      </div>

      <div>
        <Nav />
      </div>
    </div>
  );
};

export default Load;
