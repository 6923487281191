import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/Nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Nav = () => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <div className="nav">
      <div className="nav-container">
        <div className="navbar small:gap-[20vw] med:gap-[60vw] large:gap-[70vw]">
          <Link to="/">
            <h1 className="nav-title small:w-[60vw] med:w-[30vw] large:w-[15vw] Vibes">
              Præstø Smørrebrød
            </h1>
          </Link>
          
          <div className="menu-toggle" onClick={() => setNavOpen(!navOpen)}>
            <div className={navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
              <span className={navOpen ? "lineTop spin" : "lineTop"} />
              <span className={navOpen ? "lineBottom spin" : "lineBottom"} />
            </div>
          </div>
        </div>
        <div
          className="nav-overlay"
          style={{
            top: navOpen ? "0" : "-150%",
            transitionDelay: navOpen ? "0s" : "0s",
          }}
        >
         
          <Link to="/menukort/smørrebrød">
            <p className="text-white mt-[20vh] text-center text-4xl ">
              Smørrebrød
            </p>
          </Link>
          <Link to="/menukort/sandwich">
            <p className="text-white mt-[2vh] text-center text-4xl ">
              Sandwich
            </p>
          </Link>
          {/* <Link to="/menukort/Nyheder">
            <p className="text-yellow-400 mt-[2vh] text-center text-4xl animate-pulse">
              Nyheder
            </p>
          </Link> */}
          <Link to="/menukort/menuer">
            <p className="text-white mt-[2vh] text-center text-4xl ">Menuer</p>
          </Link>
          <Link to="/menukort/specials">
            <p className="text-white mt-[2vh] text-center text-4xl ">
              Specials
            </p>
          </Link>
          <Link to="/menukort/dessert">
            <p className="text-white mt-[2vh] text-center text-4xl ">Dessert</p>
          </Link>
          <Link to="/menukort/diverse">
            <p className="text-white mt-[2vh] text-center text-4xl ">Diverse</p>
          </Link>
          <Link to="/menukort/bestillingssedler">
            <p className="text-white mt-[2vh] text-center text-4xl ">Bestillingssedler</p>
          </Link>

          <Link to="#">
            <h1 className="text-white text-center mt-[15vh] text-3xl Vibes">
              Præstø Smørrebrød
              <br />
              <div className="text-lg">Jernbanevej 4 Præstø</div>
            </h1>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Nav;
