const Item = (props) => {
  return (
    <div className="relative overflow-hidden small:flex-none small:p-3 med:p-2 med:w-[40vw] large:w-[30vw]">
      <img
        src={props.img}
        alt="products"
        className="small:w-[70vw] small:h-[20vh] object-cover rounded-t-xl "
      />
      <img src={props.new} className="absolute mt-[-19vh] small:ml-[65%] med:ml-[72%] large:ml-[80%] h-[25%] object-cover animate-pulse"/>
        


      <div className="rounded-b-xl bg-black flex items-center flex-col ">
        <h2 className="text-white text-sm">{props.name}</h2>
        <h3 className="text-white text-sm">{props.desc}</h3>
        <h3 className="text-white text-sm">{props.desc2}</h3>
        <h4 className="text-green-400 Bebas">{props.price}</h4>
      </div>
    </div>
  );
};

export default Item;
