import Nav from "../Components/Nav";
import Item from "../Components/Sub-Components/Item";



const briobolle = "30,00 DKK";
const frikadelle = "20,00 DKK";
const sodavand = "25,00 DKK";
const icetea = "23,00 DKK";
const vand = "16,00 DKK";
const energidrik = "20,00 DKK";
const cocio = "16,00 DKK";
const kaffe = "22,00 DKK";
const FranskHotdog = "32,00 DKK";
const øl = "20,00 DKK";


const Diverse = () => {
  return (
    <div>
      <Nav />
      <div className=" relative h-[100vh] gap-5 max-h-screen">
        <p className="text-white text-center text-3xl Bebas mt-[13vh] border-b">
          Diverse
        </p>

        <div className="mt-[2vh] mb-10 overflow-y-auto w-full flex flex-wrap justify-center items-center">
          <Item
            img="/Assets/brio.jpg"
            name="Briochebolle m. Pålæg"
            price={briobolle}
          />
          <Item
            img="/Assets/6-2.jpg"
            name="Lun Frikadelle"
            price={frikadelle}
          />
          <Item img="/Assets/6-3.jpg" name="Sodavand" price={sodavand} />
          <Item img="/Assets/6-4.jpg" name="Ice-Tea" price={icetea} />
          <Item img="/Assets/6-5.jpg" name="Kildevand" price={vand} />
          <Item
            img="/Assets/6-6.jpg"
            name="Energidrik"
            price={energidrik}
          />
          <Item img="/Assets/6-7.jpg" name="Cocio" price={cocio} />
          <Item img="/Assets/6-8.jpg" name="Kaffe To-Go" price={kaffe} />
          <Item img="/Assets/template.png" name="Te" price={kaffe} />
          <Item img="/Assets/template.png" name="Øl 0,0%" price={øl} />
          {/* <Item img="/Assets/template.png" name="Fransk Hotdog" price={FranskHotdog} /> */}
        </div>
      </div>
    </div>
  );
};

export default Diverse;
